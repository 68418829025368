import React, { Component } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import '../styles/main.scss'
import ClientsBox from '../components/index-page/clients-box'
import logos from '../js/logos'
import AnimateComponent from '../components/animate-component'
import {graphql} from "gatsby";

class Klienci extends Component {

    componentDidMount() {
        // const pagination = require('../js/pagination');
    }

    render() {
        const acfFields = this
            .props
            .data
            .wordpressPage
            .acf
    return (

            <Layout>
                <SEO title="Klienci | Świecki Kancelaria"/>
                <AnimateComponent styleName="clients-page" id="clients-page" tag="section">
                    <div className="clients-page__container">

                        <header className="clients-page__header wow zoomIn">
                            <div className="clients-page__title-container">
                                <h1>
                                    Pracujemy dla
                                </h1>
                                <h2>
                                    Rezultatem naszej dobrej pracy i osiąganych sukcesów procesowych jest stale
                                    rosnące grono Klientów.
                                </h2>
                            </div>
                            <div className="clients-page__text-block">
                                <p>
                                    Swoje sprawy powierzają nam duże przedsiębiorstwa, rodzinne firmy oraz klienci
                                    indywidualni.
                                </p>
                            </div>
                        </header>

                        <div className="clients-page__clients-list">
                            {acfFields.zaufali_nam_klienci
                                .map((el, i) => {
                                    return (<ClientsBox
                                        key={i}
                                        logo={el.logo.localFile.publicURL}
                                        boxClass='clients-page__client'
                                        imgClass='clients-page__photo'/>)
                                })}
                        </div>
                        {/* <button className="clients-page__button" id="load-more">Załaduj więcej</button> */}
                    </div>
                </AnimateComponent>
            </Layout>
        )

    }
}

export default Klienci

export const pageQuery = graphql`
    query {
        wordpressPage( slug: {eq: "home"}) {
            id
            title
            content
            excerpt
            date
            modified
            slug
            status
            acf {
              zaufali_nam_klienci {
                logo {
                  localFile {
                    publicURL
                  }
                }
              }
            }
        }


    }
`